import React, { useState } from "react";
import "./HomeService.scss";

// Import the images for the services
import cloudComputingImage from "../assets/images/cloud-computing.png";
import desktopAppsImage from "../assets/images/desktop-apps.png";
import mobileAppImage from "../assets/images/mobile-app.png";
import dataScienceImage from "../assets/images/data-science.png";
import webAppImage from "../assets/images/web-app-dvelopment.png";
import iotAppImage from "../assets/images/iot-app.png";
import customWebAppImage from "../assets/images/custom-web-app.png";
import uiuxDesignImage from "../assets/images/UIUX-design.png";
import reactNativeAppImage from "../assets/images/react-app.png";

const HomeService = () => {
  const servicesData = [
    {
      title: "Cloud Computing",
      text: "Providing scalable, reliable, and efficient solutions tailored to our clients' needs. Ensuring optimal performance and seamless integration.",
      image: cloudComputingImage,
    },
    {
      title: "Desktop Apps",
      text: "Develop applications that run natively on your Desktop/PC leveraging the underlying platform ecosystem, security and reliability.",
      image: desktopAppsImage,
    },
    {
      title: "Mobile App Development",
      text: "Develop stunning, resilient, and futuristic mobile solutions.",
      image: mobileAppImage,
    },
    {
      title: "Data Science",
      text: "Leveraging advanced analytics, machine learning, and big data to drive insights, optimize strategies, and innovate solutions.",
      image: dataScienceImage,
    },
    {
      title: "Web App Development",
      text: "Craft user-friendly, scalable, and secure web applications.",
      image: webAppImage,
    },
    {
      title: "IoT App Development",
      text: "Revolutionize customer interactions with devices.",
      image: iotAppImage,
    },
    {
      title: "Custom Web App",
      text: "Elevate your digital presence with distinctive features and a captivating web application.",
      image: customWebAppImage,
    },
    {
      title: "UI/UX Design",
      text: "Create designs that are visually captivating and effortlessly user-friendly.",
      image: uiuxDesignImage,
    },
    {
      title: "React Native App Development",
      text: "Delivering truly native apps that seamlessly enhance your user's experience.",
      image: reactNativeAppImage,
    },
  ];

  const [selectedServiceImage, setSelectedServiceImage] = useState(
    servicesData[0].image
  );
  const [activeServiceIndex, setActiveServiceIndex] = useState(0);

  const handleMouseEnter = (index, image) => {
    setSelectedServiceImage(image);
    setActiveServiceIndex(index);
  };

  const handleKeyDown = (event, index, image) => {
    if (event.key === "Enter" || event.key === " ") {
      handleMouseEnter(index, image);
      event.preventDefault();
    }
  };

  return (
    <div className="container">
      <div className="section-row">
        <div className="section-col services-left">
          <div className="service-img">
            <img
              className="img-fluid"
              src={selectedServiceImage}
              alt={`Preview of ${servicesData[activeServiceIndex].title} service`}
            />
          </div>
        </div>
        <div className="section-col services-right">
          <h1>Our Services</h1>
          <p>
            Transforming ideas into powerful digital solutions, we specialize in
            mobile, web, IoT, AI and cloud innovation. Leveraging cutting-edge
            technologies, we create secure, scalable and robust products to
            elevate your business.
          </p>
          <div className="service-list">
            {servicesData.map((service, index) => (
              <div
                key={index}
                className={`services ${
                  index === activeServiceIndex ? "active" : ""
                }`}
                role="button"
                tabIndex={0} // Make it keyboard accessible
                onMouseEnter={() => handleMouseEnter(index, service.image)}
                onKeyDown={(event) =>
                  handleKeyDown(event, index, service.image)
                } // Handle keyboard events
                aria-current={index === activeServiceIndex ? "true" : "false"} // Indicate the active service
                aria-label={service.title} // Provide context for screen readers
              >
                <div className="service-title">{service.title}</div>
                <div className="service-text">{service.text}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeService;
