import React from "react";

function InIcon() {
  return (
    <>
      <svg width="17.884" height="17.884" viewBox="0 0 17.884 17.884">
        <path
          id="Path_2"
          data-name="Path 2"
          d="M8.643,8.232h3.321V9.886A3.81,3.81,0,0,1,15.512,8.08c3.533,0,4.372,1.894,4.372,5.369v6.435H16.307V14.24c0-1.979-.478-3.095-1.7-3.095-1.689,0-2.391,1.2-2.391,3.094v5.645H8.643ZM2.51,19.732H6.086V8.08H2.51ZM6.6,4.28a2.262,2.262,0,0,1-.673,1.611A2.3,2.3,0,0,1,2,4.28a2.266,2.266,0,0,1,.673-1.612,2.313,2.313,0,0,1,3.252,0A2.27,2.27,0,0,1,6.6,4.28"
          transform="translate(-2 -2)"
          fill="#1778be"
          fill-rule="evenodd"
        />
      </svg>
    </>
  );
}

export default InIcon;
