import React from "react";

function MailIcon() {
  return (
    <>
      <svg width="18.1" height="13.944" viewBox="0 0 18.1 13.944">
        <g
          id="Group_27"
          data-name="Group 27"
          transform="translate(-1.95 -4.25)"
        >
          <path
            id="Path_8"
            data-name="Path 8"
            d="M3,6.778A1.778,1.778,0,0,1,4.778,5H17.222A1.778,1.778,0,0,1,19,6.778v8.889a1.778,1.778,0,0,1-1.778,1.778H4.778A1.778,1.778,0,0,1,3,15.667Z"
            fill="none"
            stroke="#1778be"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1.5"
          />
          <path
            id="Path_9"
            data-name="Path 9"
            d="M3,7l8,6,8-6"
            fill="none"
            stroke="#1778be"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1.5"
          />
        </g>
      </svg>
    </>
  );
}

export default MailIcon;
