import React from "react";

function PhoneIcon() {
  return (
    <>
      <svg width="16" height="16" viewBox="0 0 16 16">
        <path
          id="Path_14"
          data-name="Path 14"
          d="M17.222,11A6.184,6.184,0,0,0,11,4.778V3a7.738,7.738,0,0,1,3.123.634,8.032,8.032,0,0,1,4.244,4.244A7.732,7.732,0,0,1,19,11Zm-3.556,0A2.656,2.656,0,0,0,11,8.333V6.556A4.438,4.438,0,0,1,15.444,11Zm4.4,8a13.347,13.347,0,0,1-5.489-1.211,16.406,16.406,0,0,1-4.933-3.434A16.428,16.428,0,0,1,4.212,9.422,13.354,13.354,0,0,1,3,3.933.911.911,0,0,1,3.933,3h3.6a.826.826,0,0,1,.556.212.813.813,0,0,1,.289.5l.578,3.111a1.568,1.568,0,0,1-.022.6.935.935,0,0,1-.244.422L6.533,10.022a10.75,10.75,0,0,0,1.055,1.588,15.63,15.63,0,0,0,1.345,1.478,15.681,15.681,0,0,0,1.444,1.278,13.93,13.93,0,0,0,1.6,1.077l2.089-2.089a1.241,1.241,0,0,1,.523-.3A1.444,1.444,0,0,1,15.222,13l3.067.622a1.027,1.027,0,0,1,.511.323.783.783,0,0,1,.2.522v3.6a.911.911,0,0,1-.933.933M5.689,8.333,7.156,6.867,6.778,4.778H4.8q.111.911.311,1.8a9.735,9.735,0,0,0,.578,1.756m7.956,7.956a11.03,11.03,0,0,0,1.767.6,10.854,10.854,0,0,0,1.811.289V15.222L15.133,14.8Zm0,0"
          transform="translate(-3 -3)"
          fill="#1778be"
        />
      </svg>
    </>
  );
}

export default PhoneIcon;
