import React from "react";

function FbIcon() {
  return (
    <>
      <svg width="9.296" height="16.989" viewBox="0 0 9.296 16.989">
        <path
          id="Path_1"
          data-name="Path 1"
          d="M8.944,19.489h3.577V12.327h3.223L16.1,8.768H12.521v-1.8a.894.894,0,0,1,.894-.894H16.1V2.5H13.415A4.471,4.471,0,0,0,8.944,6.971v1.8H7.156L6.8,12.327H8.944Z"
          transform="translate(-6.802 -2.5)"
          fill="#1778be"
        />
      </svg>
    </>
  );
}

export default FbIcon;
