import React from "react";

function CloseIcon() {
  return (
    <>
      <svg width="15" height="14.983" viewBox="0 0 15 14.983">
        <path
          id="Close"
          d="M14.568,13.49l6.165-6.131a.742.742,0,0,0,0-1.058.76.76,0,0,0-1.068,0L13.5,12.427,7.289,6.209A.757.757,0,0,0,6.22,7.281l6.212,6.213L6.2,19.693a.743.743,0,0,0,0,1.058.76.76,0,0,0,1.068,0L13.5,14.557l6.192,6.192a.757.757,0,0,0,1.068-1.071Z"
          transform="translate(-5.976 -5.988)"
          fill="#fff"
        />
      </svg>
    </>
  );
}

export default CloseIcon;
