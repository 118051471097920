import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-scroll";
import "./Header.scss";
import logo from "../assets/images/logo.svg";
import CloseIcon from "../assets/icons/CloseIcon";
import NavIcon from "../assets/icons/NavIcon";

const Header = () => {
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;
      setScrolled(offset > 0);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  let headerClass = scrolled ? "header sticky" : "header";

  const [isActive, setIsActive] = useState(false);
  const elementRef = useRef(null); // Reference to the element with the class

  const handleAddClass = () => {
    setIsActive(true);
    // Focus the first nav link when opening
    const firstNavLink = elementRef.current.querySelector("a");
    if (firstNavLink) firstNavLink.focus();
  };

  const handleRemoveClass = () => {
    setIsActive(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (elementRef.current && !elementRef.current.contains(event.target)) {
        setIsActive(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [elementRef]);

  return (
    <header className={headerClass}>
      <div className="container">
        <div className="header-inner">
          <div className="logo-section">
            <Link              
              to="section1"
              spy={true}
              smooth={true}
              duration={500}
              aria-label="Go to Home section"
            >
              <img className="img-fluid" src={logo} alt="Aquodio logo" />
            </Link>            
          </div>
          <div className="right-section">
            <button
              className="open-nav"
              onClick={handleAddClass}
              aria-label="Open navigation menu"
              aria-expanded={isActive}
            >
              <NavIcon />
            </button>
            <nav
              ref={elementRef}
              className={isActive ? "open header-nav" : "header-nav"}
              role="navigation"
              aria-label="Main Navigation"
            >
              <button
                className="close-nav"
                onClick={handleRemoveClass}
                aria-label="Close navigation menu"
                disabled={!isActive}
              >
                <CloseIcon />
              </button>
              <div className="nav-items">
                <Link
                  activeClass="active"
                  to="section1"
                  spy={true}
                  smooth={true}
                  duration={500}
                  aria-label="Go to Home section"
                >
                  Home
                </Link>
                <Link
                  activeClass="active"
                  to="section2"
                  spy={true}
                  smooth={true}
                  duration={500}
                  aria-label="Go to About Us section"
                >
                  About Us
                </Link>
                <Link
                  activeClass="active"
                  to="section3"
                  spy={true}
                  smooth={true}
                  duration={500}
                  aria-label="Go to Why We Do It section"
                >
                  Why We Do It
                </Link>
                <Link
                  activeClass="active"
                  to="section4"
                  spy={true}
                  smooth={true}
                  duration={500}
                  aria-label="Go to Services section"
                >
                  Services
                </Link>
                <Link
                  activeClass="active"
                  to="section5"
                  spy={true}
                  smooth={true}
                  duration={500}
                  aria-label="Go to Products section"
                >
                  Products
                </Link>
                <Link
                  spy={true}
                  smooth={true}
                  duration={500}
                  aria-label="Go to Blog section"
                >
                  Blog
                </Link>
                <Link
                  activeClass="active"
                  to="section7"
                  spy={true}
                  smooth={true}
                  duration={500}
                  aria-label="Go to Contact Us section"
                >
                  Contact Us
                </Link>
              </div>
            </nav>
            <div className="right-button">
              <Link
                className="primary-btn"
                to="section7"
                spy={true}
                smooth={true}
                duration={500}
                aria-label="Connect with us"
              >
                Let's Connect
              </Link>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
