import React from "react";

function InstaIcon() {
  return (
    <>
      <svg width="18" height="17.996" viewBox="0 0 18 17.996">
        <g id="Page-1" transform="translate(0 -0.001)">
          <g id="Dribbble-Light-Preview" transform="translate(0 0.001)">
            <g id="icons">
              <path
                id="instagram-_167_"
                data-name="instagram-[#167]"
                d="M289.283,7279.108a4.965,4.965,0,0,0-5.209,5.2c-.041.9-.283,7.74.416,9.531a4.531,4.531,0,0,0,2.62,2.61,6.312,6.312,0,0,0,2.174.414c7.974.359,10.931.171,12.177-3.024a6.494,6.494,0,0,0,.416-2.169c.365-7.992-.059-9.729-1.449-11.115-1.1-1.1-2.4-1.845-11.144-1.448m.073,16.154a5.279,5.279,0,0,1-1.663-.306,2.954,2.954,0,0,1-1.7-1.7c-.532-1.358-.355-7.83-.308-8.874a3.836,3.836,0,0,1,.977-2.69c.9-.892,2.053-1.333,9.931-.973a3.839,3.839,0,0,1,2.693.973c.9.891,1.34,2.061.978,9.908a4.9,4.9,0,0,1-.308,1.656c-.811,2.079-2.676,2.367-10.6,2.007m7.325-12.042a1.075,1.075,0,1,0,1.075-1.07,1.071,1.071,0,0,0-1.075,1.07m-8.3,4.771a4.6,4.6,0,1,0,4.6-4.59,4.591,4.591,0,0,0-4.6,4.59m1.614,0a2.985,2.985,0,1,1,2.984,2.979,2.98,2.98,0,0,1-2.984-2.979"
                transform="translate(-284 -7279.001)"
                fill="#1778be"
                fill-rule="evenodd"
              />
            </g>
          </g>
        </g>
      </svg>
    </>
  );
}

export default InstaIcon;
