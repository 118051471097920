import axios from "axios"

//API URL
const API_URL = process.env.REACT_APP_API_URL;

export const submitContactUs = async (contactus) => {
    const response = await axios.post(API_URL, contactus, { 
        validateStatus:function(status) {
            return status == 200
        }
    });
    console.log("Inside the Service, Response Data Received:", response.data);
    return response.data;
};
