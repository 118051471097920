import React from "react";

function NavIcon() {
  return (
    <>
      <svg width="20" height="14" viewBox="0 0 20 14">
        <path
          id="_9251936921655473165"
          data-name="9251936921655473165"
          d="M3,12H21M3,6H21M3,18H15"
          transform="translate(-2 -5)"
          fill="none"
          stroke="#fff"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
        />
      </svg>
    </>
  );
}

export default NavIcon;
